import { DataGrid } from "devextreme-react"
import { Column, Editing, Grouping, GroupPanel, Sorting, Summary,  TotalItem, GroupItem} from "devextreme-react/data-grid";
import React, { useEffect, useState } from "react";
import { ownStore } from "../../../AppOwnState/ownState";
import { ChangePageDataSetState, ExecuteLayoutEventMethods, GetControlPropertyFromStoreOrRefData } from "../../../General/commonFunctions";
import PureJSComponentFactory from "../../Pages/Factory/pureJSComponentFactory";
 
export const FactsDevGrid = (props) => {

    const getCurrentDataSet = () => {
        let listds = ChangePageDataSetState(props.datasets);
        if (!listds) return null;
        return listds[props.datasets[0]];
    }
    const getCurrentColumns = (data) => {
        let columninfo = props?.columnspecs ?? GetControlPropertyFromStoreOrRefData("[" + props.columninfo?.dset + "]");
        if (!columninfo) return null; 
        return columninfo;
    }
    const getSummaryItems = (data) => {
        let summaryIteminfo = props?.summaryItems;
        if (!summaryIteminfo) return null; 
        return summaryIteminfo;
    } 
     const getSummaryGroupItems = (data) => {
        let summaryGroupIteminfo = props?.summaryGroupItems;
        if (!summaryGroupIteminfo) return null; 
        return summaryGroupIteminfo;
    }


    const [autoExpandAll, setAutoExpandAll] = useState(true);

    const [data, setData] = useState(getCurrentDataSet());

    const [columns, setColumns] = useState(getCurrentColumns(data));
    const [summaryItems, setSummaryItems] = useState(getSummaryItems(data));
    const [summaryGroupItems, setSummaryGroupItems] = useState(getSummaryGroupItems(data));

    useEffect(() => {
        let unsubscribe = ownStore.subscribe((storeInfo) => {
            if (props.datasets[0] === storeInfo.dset || props.datasets[0] === "this" ||
                props.watch?.includes(storeInfo.dset)) {
                let newDS = getCurrentDataSet();
                setData(newDS);
            }
        });
        return () => {
            if (unsubscribe) unsubscribe();
        }
    });

    
    const handleRowDblClick = async (data) => {
        await ExecuteLayoutEventMethods(props.whenrowdblclick, data);
    }
    const handleRowClick = async (data) => {
        await ExecuteLayoutEventMethods(props.whenrowclick, data);
    }
    const handleSelectionChange = async (data) => {
        await ExecuteLayoutEventMethods(props.whenselectionchange, data);
    }
    const handleFocusedRowChanged = async (data) => {
        await ExecuteLayoutEventMethods(props.whenfocusedrowchanged, data);
    }
    return (<DataGrid
        dataSource={data}
        // defaultColumns={columns}
        
        focusedRowEnabled={true}
        width="100%"
        allowColumnReordering={true}
        allowColumnResizing={true}
        showColumnLines={true}
        showRowLines={true}
        showBorders={true}
        
        onRowDblClick={(t) => handleRowDblClick(t?.data)}
        onRowClick={(t) => handleRowClick(t?.data)}
        onSelectionChanged={(t) => handleSelectionChange(t?.row?.data)}
        onFocusedRowChanged={(t)=> handleFocusedRowChanged(t?.row?.data) }
        
        {...props}   >
        {  props?.allowSorting &&  
             <Sorting mode="multiple" />
            }
        {  props?.editable &&  
            <Editing

                mode="cell"
                allowUpdating={props.editable?.canupdate ?? true}
                allowAdding={props.editable?.canadd ?? true}
                allowDeleting={props.editable?.candelete ?? true}
                {...props.editable}
            />}
        <GroupPanel visible={true} />
        <Grouping autoExpandAll={autoExpandAll} />
        <Summary>
             {summaryItems?.map((t, i) => {
                 return (<TotalItem {...t}/>)
             })}  
             
               {summaryGroupItems?.map((t, i) => {
                return (<GroupItem {...t}/>)
            })}
        </Summary>
        {columns?.map((t, i) => {
          
            return (<Column key={i} 
                
                caption={t[props.columninfo?.captionfield]}
                dataField={t[props.columninfo?.datafield]}
                width={t[props.columninfo?.widthfield]}
                {...t}
                editCellRender={(dataInfo) => {
                    console.log(dataInfo); 
                    const scaff = new PureJSComponentFactory().scaffoldComponent(
                        t[props.columninfo?.editprops?.chldfield], dataInfo);
                    if (scaff) return scaff;
                    else return <span>{dataInfo?.displayValue}</span>;
                }}

                groupCellRender={(dataInfo) => {
                    console.log(dataInfo); 
                    const scaff = new PureJSComponentFactory().scaffoldComponent(
                        t.groupProps, dataInfo);
                    if (scaff) return scaff;
                    else return <span>{dataInfo?.value}</span>;
              }}


                 />);
        })}

    </DataGrid>);




}
